import React from "react";
import monio from "../assets/monio.png";
import bandera from "../assets/bandera.jpg";

export default function Bandera() {
    

    return (
        <div className="my-5">
            <div className="franja text-center d-none d-sm-block"><img src={monio} alt="" className="monio img-fluid d-none d-sm-block" /></div>
            <img src={bandera} alt="" className="img-fluid fullWidth"/>
        </div>
    )
}