import React, {useState, useEffect} from "react";
import Menugallerybutton from './menugallerybutton';
import axios from 'axios';
import Spinner from './spinner';

export default function Menugallery( {setgal} ) {

    const [galerias, setGalerias] = useState([]);

    const [galeria, setGaleria] = useState(0);

    const [cargando, setCargando] = useState(false);

    const API_BASE = "https://gracielavidalluxurycoutureforpets.com/";

    useEffect(() => {        
        const fetchFotos = async () => {
            setCargando(true);
            try {
                const result = await axios(`${API_BASE}galerias.php`);

                setGalerias(result.data);

                setgal(result.data[0].images);
                setCargando(false);
            }catch (error) {
                setCargando(false);
            }
            
        }
        fetchFotos();
        // eslint-disable-next-line
    }, []);

    const handleClick = v => {
        setGaleria(v);
        const photos = galerias.filter(item => item.id === v)[0].images;
        setgal(photos);    
    }

    return (
        <>
        {cargando ? <Spinner /> : null}
        <ul className="galNav mb-5 text-center">
            {
                galerias.map( ({id, label}) =>  <Menugallerybutton key={id} v={id} galeria={galeria} handleClick={handleClick} label={label} />)
            }
        </ul>
        </>
    )
}