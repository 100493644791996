import React from "react";
import logo from "../assets/logo horizontal.png";
import bgfooter from "../assets/bgfooter.png";
import Menu from './menu';

export default function Footer({executeScroll}) {

    return (
        <>
        <div className="row" style={{lineHeight: 4}}>
            <div className="col-1 d-md-none d-lg-block"></div>
            <div className="col-sm-12 col-md-4 px-5 px-md-2 py-2 py-md-5 text-sm-center">
                <img src={logo} alt="logo" className="logo img-fluid" />
            </div>
            <div className="col-sm-12 col-md-4 px-1 px-md-2 py-2 py-md-5">
                <div className="p-center text-center">
                    <p>Córdoba - Argentina<br />
                Email: <a href="mailto:gvidalluxurycoutureforpets@gmail.com" >gvidalluxurycoutureforpets@gmail.com</a><br />
                Tel: <a href="https://api.whatsapp.com/send?phone=543512474360">+54 9 351 2474360</a></p>
                </div>
                

            </div>
            <div className="col-sm-12 col-md-3 px-1 px-md-2 py-2 py-md-5 align-self-end menu-footer d-none d-sm-block">
                <div className="p-center text-center">
                    <Menu executeScroll={executeScroll} />
                </div>
            </div>
            <div className="col-1 d-md-none d-lg-block"></div>
        </div>
        <div className="row bgfooter d-none d-sm-block" style={{backgroundImage:`url(${bgfooter})`}}>

        </div>
        </>
    )
}