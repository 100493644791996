import React from "react";
import instagram from "../assets/instagram.png";
import insta from "../assets/insta.jpg";

export default function Instagram() {

    return (
        <div className="row my-5">
            <div className="banda-insta text-center px-4">
                <h3 className="my-5">Encontrá más sobre mis diseños en: <a href="https://www.instagram.com/gvidalluxurycoutureforpets/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /> Instagram</a></h3>
                <div className="instacuad" style={{backgroundImage:`url(${insta})`}}>
                    <img src={insta} className="img-fluid" alt="" />
                </div>
                
            </div>
        </div>
    )
}