import React, {useState} from "react";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Spinner from './spinner';

export default function Pedidos() {

    const API_BASE = "https://gracielavidalluxurycoutureforpets.com/";
    
    const { register, errors, handleSubmit } = useForm();
    const [message, setMessage] = useState('');
    const [cargando, setCargando] = useState(false);

    const onSubmit = data => {
        enviarForm(data);
    };

    const enviarForm = async data => {
        setCargando(true);
		try {
            
			const res = await axios.post(`${API_BASE}contacto.php`, data, {
				headers: {
					"Content-Type": "multipart/form-data"
				}              
			});
			setMessage(res.data.message);
            if(!res.data.error){

            }
            setCargando(false);
		} catch(err) {
			if(err.data===undefined){
				setMessage("ocurrió un problema en el servidor, disculpe las molestias.");
			}else{
				if(err.status === 500) {
					setMessage("ocurrió un problema en el servidor (500), disculpe las molestias.");
				} else {
					setMessage(err.data.message);
				}
            }
            setCargando(false);
        }
    }

    return (
        <>
        <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-8 px-5 py-5 text-center">
                <h2 className="mb-4">Haga su pedido</h2>
                <p>Cada diseño es hecho a pedido y según las necesidades de su mascota. Por favor, no dude en escribirme que me contactaré con usted a la brevedad.</p>
            </div>
        </div>
        <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-8 px-5 py-2 text-center">
                {cargando ? <Spinner /> : null}
                {message !== '' ? <p>{message}</p> : 
                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                    <div className="form-row text-left">

                        <div className="col-12 mb-4">
                            <label htmlFor="nombre">Nombre y Apellido</label>
                            <input
                                name="nombre"
                                id="nombre"
                                className="form-control"
                                ref={register({
                                    validate: value => value.length > 3
                                })}
                            />
                            {errors.nombre && <div className="text-danger small">escriba su nombre</div>}
                        </div>

                        <div className="col-12 mb-4">
                            <label htmlFor="telefono">Teléfono</label>
                            <input
                                name="telefono"
                                id="telefono"
                                className="form-control"
                                ref={register({
                                    validate: value => value.length > 8
                                })}
                            />
                            {errors.nombre && <div className="text-danger small">escriba un teléfono (al menos 9 números)</div>}
                        </div>

                        <div className="col-12 mb-4">
                            <label htmlFor="email">Email</label>
                            <input
                                name="email"
                                id="email"
                                className="form-control"
                                ref={register({
                                required: 'Required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                }
                                })}
                            />
                            {errors.email && <div className="text-danger small">escriba un email válido</div>}

                        </div>


                        <div className="col-12 mb-4">
                            <label htmlFor="consulta">Consulta</label>
                            <textarea 
                                className="form-control" 
                                name="consulta"
                                id="consulta" 
                                rows="3"
                                ref={register({
                                    validate: value => value.length > 8
                                })}
                                >
                            </textarea>
                            {errors.email && <div className="text-danger small">escriba su consulta</div>}
                        </div>
                            
                        
                        <div className="col-12 text-center">
                            <button type="submit" className="btn btn-primary">Enviar</button>
                        </div>
                        
                        
                    </div>
                </form>
                }
            </div>
        </div>
        </>
    )
}