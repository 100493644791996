import React from "react";
import logo from "../assets/logo horizontal.png";
import Menu from './menu';

export default function Header({executeScroll}) {

    return (
        <div className="row">
            <div className="col-1 d-md-none d-lg-block"></div>
            <div className="col-sm-12 col-md-4 px-5 px-md-2 py-2 py-md-5 text-sm-center">
                <img src={logo} alt="logo" className="logo img-fluid" />
            </div>
            <div className="col-sm-12 col-md-7 px-1 px-md-2 py-2 py-md-5 align-self-end ml-auto">
                <Menu executeScroll={executeScroll} />
            </div>
            <div className="col-1 d-md-none d-lg-block"></div>
        </div>
    )
}