import React, {useState, useEffect} from "react";

export default function Menugallerybutton({v, handleClick, label, galeria}) {

    const [classbtn, setclassbtn] = useState("");

    useEffect(() => {        
        if(galeria === v){
            setclassbtn("active");
        }else{
            setclassbtn("");
        }
        // eslint-disable-next-line
    }, [galeria]);

    return (
        <li className="nav-item">
            <button onClick={() => handleClick(v)} type="button" className={`btn btn-link nav-link ${classbtn}`} >{label}</button>
        </li>
    )
}