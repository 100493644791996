import React, {useRef} from 'react';
import Slider from './componentes/slider';
import Galeria from './componentes/galeria';
import Header from './componentes/header';
import SmoothScrolling from "./componentes/smoothScrolling";
import Bienvenidos from './componentes/bienvenidos';
import Bandera from './componentes/bandera';
import Pedidos from './componentes/pedidos';
import Instagram from './componentes/instagram';
import Footer from './componentes/footer';

function App() {

    const inicioRef = useRef(null);
    const galeriRef = useRef(null);
    const pedidosRef = useRef(null);
    const contactoRef = useRef(null);    

    const executeScroll = s => {
        s = parseInt(s);
        if( s === 1 ) SmoothScrolling.scrollTo(inicioRef);
        if( s === 2 ) SmoothScrolling.scrollTo(galeriRef);
        if( s === 3 ) SmoothScrolling.scrollTo(pedidosRef);
        if( s === 4 ) SmoothScrolling.scrollTo(contactoRef);
    }

    return (
        <div className="container-fluid">

            <div ref={inicioRef}>
                <Header executeScroll={executeScroll} />
            </div>

            <Slider />

            <Bienvenidos />

            <div ref={galeriRef}>
                <Galeria /> 
            </div>

            <Bandera /> 

            <div ref={pedidosRef}>
                <Pedidos /> 
            </div>

            <Instagram />

            <div ref={contactoRef}>
                <Footer executeScroll={executeScroll} />
            </div>
            

        </div>
    );
}

export default App;
