import React from "react";
import bm from "../assets/bm.jpg";
import icono from "../assets/icono.png";
import firma from "../assets/firma.png";

export default function Bienvenidos() {
    

    return (
        <div className="row">
            <div className="col-md-12 col-lg-6 px-5 py-5 text-center">
                <h2>Bienvenidos </h2>
                <p>Graciela Vidal, es una diseñadora argentina dedicada a la alta costura adaptada a mascotas. Desde el 2014 comenzó a estudiar formalmente con la profesora Paola Minuzzi y actualmente, continúa investigando y creciendo en este campo teniendo en cuenta la anatomía y el confort de las mascotas. Fruto del trabajo y la pasión puesta en cada diseño, Graciela Vidal participa de diferentes eventos representando a su país, como en el <a href="https://www.newyorkpetfashionshow.com/" target="_blank" rel="noopener noreferrer" >NEW YORK PET FASHION SHOW</a>, el pet fashion show más grande de Estados Unidos donde se reúnen los diseñadores de diferentes lugares del mundo.</p>
                <p><img src={icono} alt="" /></p>
                <p><i>"Me intereso en perseguir la calidad, relacionada con la selección de materiales nobles, la manufacturación, el proceso creativo, la atención a los detalles y la continua investigación del diseño en relación a la anatomía de las mascotas y su confort. Aprecio la herencia de cientos de años de alto nivel de mano de obra, en los tejidos artesanales, el diseño hecho a medida respetando las formas y la tercera dimensión en las mascotas. Mi intención al crear cada diseño es preservar estos aspectos artísticos concebidos con amor y pasión."</i></p>
                <p className="mt-5"><img src={firma} alt="" /></p>
            </div>
            <div className="col-md-12 col-lg-6 px-5 px-md-2 py-5 text-center">
                <img src={bm} alt="" className="img-fluid" />
            </div>
        </div>
    )
}