import React, {useState, useEffect} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Img from 'react-image'
import axios from 'axios';
import Spinner from './spinner';

export default function Slider() {

    const API_BASE = "https://gracielavidalluxurycoutureforpets.com/";

    const [imgSlider, setImgSlider] = useState([]);
    const [cargando, setCargando] = useState(false);


    useEffect(() => {        
        const fetchFotos = async () => {
            setCargando(true);
            try {
                const result = await axios(`${API_BASE}slider.php`);

                setImgSlider(result.data);
                setCargando(false);
            }catch (error) {
                setCargando(false);
                //console.log(error);
            }            
        }
        fetchFotos();
        // eslint-disable-next-line
    }, []);
    


    return (
        <>
        {cargando ? <Spinner /> : null}
        { imgSlider ? (
                <Carousel showArrows={true} showStatus={false} showThumbs={false} infiniteLoop={true} >
                    {
                        imgSlider.map( (img, index) => <div key={index}><Img src={img} loader={<Spinner />} alt="img" /></div>)
                    }
                </Carousel>) : null }
        </>     
    );
}