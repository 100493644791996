import React, {useState} from "react";

export default function Menu({executeScroll}) {

    const [btnActivo, setBtnActivo] = useState();

    const handleClick = e => {
        setBtnActivo(e.target.value);
        executeScroll(e.target.value);
    }

    const getActive = n => {
        if(parseInt(btnActivo) === n){
            return "active";
        }
        return "";
    }

    return (
        <ul className="mainNav text-center text-sm-center text-md-right">
            <li className="nav-item"><button onClick={handleClick} type="button" className={`btn btn-link nav-link ${getActive(1)}`} value="1" >Inicio</button></li>
            <li className="nav-item"><button onClick={handleClick} type="button" className={`btn btn-link nav-link ${getActive(2)}`} value="2" >Productos</button></li>
            <li className="nav-item"><button onClick={handleClick} type="button" className={`btn btn-link nav-link ${getActive(3)}`} value="3" >Pedidos</button></li>
            <li className="nav-item"><button onClick={handleClick} type="button" className={`btn btn-link nav-link ${getActive(4)}`} value="4" >Contacto</button></li>
        </ul>
    )
}